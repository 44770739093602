'use strict';

Gri.module({
  name: 'carousel-beta',
  ieVersion: null,
  $el: $('.carousel-beta'),
  container: '.carousel-beta',
  fn: function () {
    this.$el.owlCarousel({
      autoplay:true,
      // autoplayTimeout:3000,
      items: 1,
      dots: true,
      responsive:{
        0 : {
          items: 1
        },
        1000 : {
          items : 2
        }
      }
    });
  }
});
